import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6dd79d5a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "SearchStudent"
};
const _hoisted_2 = {
  class: "list_item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_autocomplete = _resolveComponent("el-autocomplete");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_autocomplete, {
    modelValue: _ctx.searchStr,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.searchStr = $event),
    "suffix-icon": _ctx.Search,
    "popper-class": "listCls",
    clearable: "",
    debounce: 300,
    "trigger-on-focus": true,
    "fetch-suggestions": _ctx.onSearch,
    placeholder: _ctx.placeholder,
    onSelect: _ctx.handleSelect,
    onClear: _ctx.handleClear
  }, {
    default: _withCtx(({
      item
    }) => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tag, {
      size: "small",
      type: "success"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.objectname), 1)]),
      _: 2
    }, 1024), item.mdtid ? (_openBlock(), _createBlock(_component_el_tag, {
      key: 0,
      size: "small"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.mdtid), 1)]),
      _: 2
    }, 1024)) : _createCommentVNode("", true), item.schoolName ? (_openBlock(), _createBlock(_component_el_tag, {
      key: 1,
      size: "small"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.schoolName), 1)]),
      _: 2
    }, 1024)) : _createCommentVNode("", true), item.gradeName ? (_openBlock(), _createBlock(_component_el_tag, {
      key: 2,
      size: "small"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.gradeName), 1)]),
      _: 2
    }, 1024)) : _createCommentVNode("", true), item.className ? (_openBlock(), _createBlock(_component_el_tag, {
      key: 3,
      size: "small"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.className), 1)]),
      _: 2
    }, 1024)) : _createCommentVNode("", true)]), _createVNode(_component_el_divider)]),
    _: 1
  }, 8, ["modelValue", "suffix-icon", "fetch-suggestions", "placeholder", "onSelect", "onClear"])]);
}
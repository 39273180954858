import { defineComponent, reactive, computed } from 'vue';
import { ElAutocomplete, ElTag, ElSpace, ElDivider } from 'element-plus';
import { toRefs } from '@vueuse/core';
import api from '@/api/education';
import { Search } from "@element-plus/icons-vue";
export default defineComponent({
  name: 'SearchStudent',
  components: {
    ElAutocomplete,
    ElTag,
    ElSpace,
    ElDivider
  },
  emits: ['select'],
  props: {
    placeholder: {
      type: String,
      default: '设备名/IMEI'
    }
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      searchStr: ''
    });
    let oldSearchStr;
    let searchList = [];
    const triggerOnFocus = computed(() => !!refData.searchStr.trim());
    function handleSelect(item) {
      refData.searchStr = item.objectname;
      emit('select', item);
    }
    function handleClear() {
      refData.searchStr = "";
      emit('select', null);
    }
    async function onSearch(queryString, callback) {
      const str = queryString.trim();
      if (str === oldSearchStr) {
        return callback(searchList);
      }
      searchList = [];
      oldSearchStr = str;
      try {
        if (!str) {
          handleClear();
          return;
        }
        const result = await api.studentList({
          objectname: str
        });
        searchList = !result.data ? [] : result.data.list;
      } catch (err) {
        console.warn(err);
      } finally {
        callback(searchList);
      }
    }
    return {
      ...toRefs(refData),
      handleSelect,
      handleClear,
      onSearch,
      triggerOnFocus,
      Search
    };
  }
});